import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { AccountCircle } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import '../../translations/i18n'
import * as actions from '../../store/actions/'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }))

const Navbar = (props: {user: any}) => {

    const classes = useStyles()
    const { t } = useTranslation()

    const [profileOpen, setProfileOpen] = useState<boolean>(false)
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);


    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <AppBar position="static" style={{ background: '#68c1ae' }} >
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>{t('siteTitle')}</Typography>
            <div>
            {props.user ? props.user.email : null}
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => {}}>Profile</MenuItem>
                <MenuItem onClick={() => {}}>My account</MenuItem>
                <MenuItem component={Link} to="/logout" onClick={() => {}} >Logout</MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
    )
}

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.auth.user !== null,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCheckAuthState: () => dispatch(actions.checkAuthState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)