import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import styles from './Login.module.css'
import logo from '../../assets/images/logo.png'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/'
import { useTranslation } from 'react-i18next'
import '../../translations/i18n'
import { Redirect } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    Input: {
      marginTop: '20px'    
    },
    Button: {
        width: '150px',
        marginBottom: '20px',
        '@media(max-width: 850px)': {
            margin: '20px auto',
            display: 'block'
        }
    }
  }))

const Login: React.FC<{loading: boolean, isAuthenticated: boolean, error: any, onLogin: (username: string) => VoidFunction}> = (props) => {

    const { t } = useTranslation()
    const classes = useStyles()
    const location = useLocation()

    const formSubmitHandler = () => {
      const cognitoHostedUi = process.env.REACT_APP_COGNITO_HOSTED_UI as string
      
      window.location.replace(cognitoHostedUi + '&state=' + location.pathname);
    }

    return (
        <div className={styles.Login}>
          { props.isAuthenticated && <Redirect to="/"/> } 
            <div className={styles.Logo}>
                <img src={logo} />
            </div> 
            <form className={styles.Form}>  
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={formSubmitHandler} 
                    className={`${classes.Input} ${classes.Button}`}
                >{t('login')}</Button>
                {props.loading && <div className={styles.Loading}>
                  <CircularProgress/>
                </div>}
                <div className={styles.Error}>
                  {props.error && props.error.data && !props.loading ? props.error.data.message : null}
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
      user: state.auth.user,
      loading: state.auth.loading,
      error: state.auth.error,
      isAuthenticated: state.auth.user != null
    }
  }
  
  const mapDispatchToProps = (dispatch: any )=> {
    return {
      onLogin: (code: string) => dispatch(actions.login(code)),
      onReset: () => dispatch(actions.reset())
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Login)